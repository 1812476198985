import React from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as HeartSVG } from "./assets/images/heart.svg";
import theLyricImage from "./assets/images/beginning-the-lyric.jpg";
import claphamCommonImage from "./assets/images/clapham-common-kiss.jpg";
import underground from "./assets/images/underground.png";
import beer from "./assets/images/cheers.png";

import "./beginning.scss";

const Beginning = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#782ecc");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="beginning-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div>
        <div className="beginning-container">
          <div className="heart-text-container">
            <HeartSVG className="heart"></HeartSVG>
            <h1 className="heart-text">Where it all began!</h1>
          </div>
          <div className="images-container">
            <img
              className="lyric-image"
              src={theLyricImage}
              alt="The lyric"
            ></img>

            <img
              className="clapham-image"
              src={claphamCommonImage}
              alt="Clapham Common"
            ></img>
          </div>
        </div>
        <img
          className="underground"
          src={underground}
          alt="undeground1"
          style={{ top: "50vh", left: "10vw", transform: "rotate(-20deg)" }}
        ></img>
        <img
          className="underground"
          src={underground}
          alt="undeground2"
          style={{ top: "45vh", left: "34vw", transform: "rotate(14deg)" }}
        ></img>
        <img
          className="underground"
          src={underground}
          alt="undeground3"
          style={{ top: "55vh", left: "34vw", transform: "rotate(25deg)" }}
        ></img>
        <img
          className="beer"
          src={beer}
          alt="beer1"
          style={{ top: "83vh", left: "21vw", transform: "rotate(30deg)" }}
        ></img>
        <img
          className="beer"
          src={beer}
          alt="beer2"
          style={{ top: "64vh", left: "66vw", transform: "rotate(-20deg)" }}
        ></img>
        <img
          className="beer"
          src={beer}
          alt="beer2"
          style={{ top: "78vh", left: "75vw", transform: "rotate(17deg)" }}
        ></img>
      </div>
    </CSSTransition>
  );
};

export default Beginning;
