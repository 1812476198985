import React from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as ExplosionSVG } from "./assets/images/explosion.svg";
import { ReactComponent as SparkleSVG } from "./assets/images/sparkle.svg";
import alfie from "./assets/images/alfie-templeman.jpg";
import taylor from "./assets/images/taylor-swift.webp";

import "./topsongs.scss";

const TopSongs = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#782ecc");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="kisses-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div className="top-songs-container">
        <div className="topsongs-text-container">
          <h1 className="heading">Our Top Songs!</h1>
        </div>
        <div className="explosion-container">
          <div className="grid-container">
            <ExplosionSVG className="explosion"></ExplosionSVG>
            <div className="songs-list-container">
              <ul className="list">
                <li className="list-item" style={{ background: "#fc891f" }}>
                  Dive - Olvia Dean
                </li>
                <li className="list-item" style={{ background: "#f4fe48" }}>
                  August - Taylor Swift
                </li>
                <li
                  className="list-item"
                  style={{ background: "#782ecc", color: "#f4fe48" }}
                >
                  Broken <br></br> - Alfie Templeman
                </li>
                <li
                  className="list-item"
                  style={{ background: "black", color: "#f4fe48" }}
                >
                  Vickie - Peach Pit
                </li>
                <li className="list-item" style={{ background: "#F873C4" }}>
                  The Family Madrigal <br></br> - Encanto
                </li>
              </ul>
            </div>
          </div>
        </div>
        <SparkleSVG
          className="sparkle"
          style={{ scale: "38%", top: "17vh", left: "16vw" }}
        ></SparkleSVG>
        <SparkleSVG
          className="sparkle"
          style={{ scale: "32%", top: "33vh", left: "73vw" }}
        ></SparkleSVG>
        <SparkleSVG
          className="sparkle"
          style={{ scale: "52%", top: "83vh", left: "20vw" }}
        ></SparkleSVG>
        <img
          src={alfie}
          alt="Alfie Templeman"
          className="album-cover"
          style={{ left: "66vw", top: "73vh", transform: "rotate(20deg)" }}
        ></img>
        <img
          src={taylor}
          alt="Taylor Swift"
          className="album-cover"
          style={{ left: "10vw", top: "23vh", transform: "rotate(-10deg)" }}
        ></img>
      </div>
    </CSSTransition>
  );
};

export default TopSongs;
