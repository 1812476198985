import React from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as SquareSVG } from "./assets/images/pink-square.svg";
import { ReactComponent as HeartSVG } from "./assets/images/heart.svg";
import "./kisses.scss";

const Kisses = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#782ecc");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="kisses-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div className="content-container">
        <SquareSVG className="square"></SquareSVG>
        <div className="text-container">
          <h1 className="text">Smooches to give you:</h1>
          <h1 className="infinite-text">∞ </h1>
          <h1 className="text">(way more than 20!)</h1>
        </div>

        <HeartSVG
          className="heart-container"
          style={{ top: "15vh", left: "20vw", transform: "rotate(15deg)" }}
        ></HeartSVG>
        <HeartSVG
          className="heart-container"
          style={{ top: "4vh", left: "73vw", transform: "rotate(-15deg)" }}
        ></HeartSVG>
        <HeartSVG
          className="heart-container"
          style={{ top: "24vh", left: "74vw", transform: "rotate(52deg)" }}
        ></HeartSVG>
        <HeartSVG
          className="heart-container"
          style={{ top: "75vh", left: "22vw", transform: "rotate(-21deg)" }}
        ></HeartSVG>
        <HeartSVG
          className="heart-container"
          style={{ top: "88vh", left: "47vw", transform: "rotate(11deg)" }}
        ></HeartSVG>
        <HeartSVG
          className="heart-container"
          style={{ top: "78vh", left: "73vw", transform: "rotate(-14deg)" }}
        ></HeartSVG>
      </div>
    </CSSTransition>
  );
};

export default Kisses;
