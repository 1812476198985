import React, { useEffect, useRef } from "react";

function FadingAudio({
  audioPath,
  fadeInDuration,
  fadeOutDuration,
  startTime,
  fadeOut,
  playAudio,
}) {
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioContextRef.current) {
      if (playAudio) {
        let isPlaying =
          audioRef.current.currentTime > 0 &&
          !audioRef.current.paused &&
          !audioRef.current.ended &&
          audioRef.current.readyState > audioRef.current.HAVE_CURRENT_DATA;

        if (isPlaying) {
          return;
        }

        audioRef.current.load();

        audioRef.current.currentTime = startTime;

        gainNodeRef.current.gain.setValueAtTime(
          0,
          audioContextRef.current.currentTime
        );

        gainNodeRef.current.gain.linearRampToValueAtTime(
          1.0,
          audioContextRef.current.currentTime + fadeInDuration
        );
        console.log("Set up linear ramp");

        audioElement.play();
        console.log("Playing audio...");
      } else {
        audioElement.pause();
        audioElement.currentTime = 0;
        console.log("Audio paused!");
      }
    }
  }, [playAudio]);

  useEffect(() => {
    if (audioContextRef.current) {
      if (playAudio && fadeOut) {
        console.log("Fading out audio...");

        gainNodeRef.current.gain.linearRampToValueAtTime(
          0.0,
          audioContextRef.current.currentTime + fadeOutDuration
        );
      }
    }
  }, [playAudio, fadeOut]);

  useEffect(() => {
    if (!audioContextRef.current) {
      audioRef.current.currentTime = startTime;
      audioContextRef.current = new AudioContext();
      sourceNodeRef.current = audioContextRef.current.createMediaElementSource(
        audioRef.current
      );
      gainNodeRef.current = audioContextRef.current.createGain();

      sourceNodeRef.current.connect(gainNodeRef.current);
      gainNodeRef.current.connect(audioContextRef.current.destination);
    }
  });

  return (
    <audio ref={audioRef}>
      <source src={audioPath} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
}

export default FadingAudio;
