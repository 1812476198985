// App.js
import React, { useState } from "react";
import SplashScreen from "./SplashScreen";
import Start from "./Start";
import FadingAudio from "./FadedAudio";
import splashMusic from "./assets/music/three-d-feelings.mp3";
import ukMapMusic from "./assets/music/vickie.mp3";
import beginningMusic from "./assets/music/dive.mp3";
import picturesMusic from "./assets/music/gummy.mp3";
import kissesMusic from "./assets/music/hannah.mp3";
import topSongsMusic from "./assets/music/friday.mp3";
import loveMusic from "./assets/music/echo.mp3";

import UKMap from "./UKMap";
import Beginning from "./Beginning";
import Pictures from "./Pictures";
import "./app.scss";
import Kisses from "./Kisses";
import TopSongs from "./TopSongs";
import Love from "./Love";

const App = () => {
  const [start, setStart] = useState(false);
  const [activeComponent, setActiveComponent] = useState(1);
  const [playAudio, setPlayAudio] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [lastTransitionExited, setLastTransitionExited] = useState(true);
  const [bgColour, setBgColour] = useState("#782ecc");
  const [audioPath, setAudioPath] = useState();
  const [startTime, setStartTime] = useState(5);

  const minComponents = 0;
  const maxComponents = 8;

  const pages = {
    SPLASH: 1,
    UK_MAP: 2,
    EU_MAP: 3,
    BEGINNING: 4,
    PICTURES: 5,
    KISSES: 6,
    TOP_SONGS: 7,
    LOVE: 8,
  };

  const audioPaths = {
    1: splashMusic,
    2: ukMapMusic,
    4: beginningMusic,
    5: picturesMusic,
    6: kissesMusic,
    7: topSongsMusic,
    8: loveMusic,
  };

  const audioStartTimes = {
    1: 5,
    2: 120,
    4: 85,
    5: 53,
    6: 0,
    7: 45,
    8: 105,
  };

  const handleTransitionEnter = () => {
    setAudioPath(audioPaths[activeComponent]);
    setStartTime(audioStartTimes[activeComponent]);
    setPlayAudio(true);
  };

  const handleTransitionExit = () => {
    setFadeOut(true);
  };

  const handleTransitionExited = () => {
    setPlayAudio(false);
    setFadeOut(false);
    setLastTransitionExited(true);
  };

  const handleTap = (event) => {
    // Calculate the tap position relative to the screen width
    const tapX = event.nativeEvent.offsetX;
    const screenWidth = window.innerWidth;

    // Determine whether the tap is on the left or right side
    if (tapX < screenWidth / 2) {
      if (minComponents < activeComponent && lastTransitionExited) {
        const nextActiveComponent = activeComponent - 1;
        setActiveComponent(nextActiveComponent); // Tapped on the left side

        if (nextActiveComponent !== pages.UK_MAP) {
          setLastTransitionExited(false);
        }
      }
    } else {
      if (activeComponent < maxComponents && lastTransitionExited) {
        const nextActiveComponent = activeComponent + 1;
        setActiveComponent(nextActiveComponent);

        if (nextActiveComponent !== pages.EU_MAP) {
          console.log("set last transition exited");
          setLastTransitionExited(false);
        }
      }
    }
  };

  return (
    <div className="splash">
      {start && <div className="clickable" onClick={handleTap}></div>}
      <div className="background-container" style={{ background: bgColour }}>
        {start && (
          <FadingAudio
            audioPath={audioPath}
            fadeInDuration={5}
            fadeOutDuration={5}
            startTime={startTime}
            fadeOut={fadeOut}
            playAudio={playAudio}
          />
        )}
        {!start && <Start setStart={setStart}></Start>}
        <SplashScreen
          show={
            start && activeComponent === pages.SPLASH && lastTransitionExited
          }
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></SplashScreen>
        <UKMap
          show={
            start &&
            (activeComponent === pages.UK_MAP ||
              activeComponent === pages.EU_MAP) &&
            lastTransitionExited
          }
          showEUMap={activeComponent === pages.EU_MAP}
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></UKMap>
        <Beginning
          show={
            start && activeComponent === pages.BEGINNING && lastTransitionExited
          }
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></Beginning>
        <Pictures
          show={
            start && activeComponent === pages.PICTURES && lastTransitionExited
          }
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></Pictures>
        <Kisses
          show={
            start && activeComponent === pages.KISSES && lastTransitionExited
          }
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></Kisses>
        <TopSongs
          show={
            start && activeComponent === pages.TOP_SONGS && lastTransitionExited
          }
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></TopSongs>
        <Love
          show={start && activeComponent === pages.LOVE && lastTransitionExited}
          setBackground={setBgColour}
          handleEnter={handleTransitionEnter}
          handleExit={handleTransitionExit}
          handleExited={handleTransitionExited}
        ></Love>
      </div>
    </div>
  );
};

export default App;
