import React from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as StarSVG } from "./assets/images/star_background.svg";
import collage from "./assets/images/photo-collage.png";
import "./pictures.scss";

const Pictures = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#fc891f");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="pictures-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div className="pictures-container">
        <div className="star-text-container">
          <StarSVG className="star"></StarSVG>
          <h1 className="star-text">We've taken over 200 photos together!</h1>
        </div>
        <div className="image-container">
          <img className="image" src={collage} alt="collage"></img>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Pictures;
