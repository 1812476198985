import React from "react";
import { CSSTransition } from "react-transition-group";
import "./splash.scss";
import { TypeAnimation } from "react-type-animation";
import { ReactComponent as StarSVG } from "./assets/images/star_background.svg";
import splashImage from "./assets/images/splash.jpg";

const SplashScreen = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#782ecc");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="splash-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div className="content-container">
        <StarSVG className="star-background"></StarSVG>
        <div className="splash-image-and-text-grid">
          <div className="splash-image-and-text-flex">
            <img className="splash-image" src={splashImage} alt=""></img>
            <TypeAnimation
              className="splash-text"
              cursor={false}
              sequence={[3500, "Our year in review!", 1000]}
              speed={10}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SplashScreen;
