import React from "react";
import { CSSTransition } from "react-transition-group";
import "./ukmap.scss";
import { ReactComponent as UKMapSVG } from "./assets/images/uk-map.svg";
import { ReactComponent as CloudSVG } from "./assets/images/Cloud.svg";
import { ReactComponent as EUMapSVG } from "./assets/images/eu-map.svg";
import seagull from "./assets/images/seagull.png";

const UKMap = ({
  show,
  showEUMap,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#f4fe48");
  }

  return (
    <div>
      <CSSTransition
        in={show}
        timeout={5000}
        classNames="ukmap-transition"
        unmountOnExit
        onEntering={handleEnter}
        onExiting={handleExit}
        onExited={handleExited}
      >
        {/* <div className="ukmap-background"> */}
        <div className="ukmap-and-title-container">
          <div className="title">
            <h1 className="title-text">
              We've travelled 7478 miles around the world together!
            </h1>
          </div>

          <div className="sliding-maps-container ">
            <CSSTransition
              in={!showEUMap}
              timeout={4000}
              classNames={"uk-slide-transition"}
              unmountOnExit
            >
              <div className="ukmap-container">
                <UKMapSVG className="ukmap"></UKMapSVG>
              </div>
            </CSSTransition>
            <CSSTransition
              in={showEUMap}
              timeout={4000}
              classNames={"eu-slide-transition"}
              unmountOnExit
            >
              <div className="ukmap-container">
                <EUMapSVG className="ukmap"></EUMapSVG>
              </div>
            </CSSTransition>
          </div>

          <img className="first-seagull" src={seagull} alt="seagull"></img>
          <img className="second-seagull" src={seagull} alt="seagull"></img>
          <img className="third-seagull" src={seagull} alt="seagull"></img>
        </div>
        {/* </div> */}
      </CSSTransition>

      {show && (
        <div className="cloud-container">
          <CloudSVG className="cloud"></CloudSVG>
        </div>
      )}
    </div>
  );
};

export default UKMap;
