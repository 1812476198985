import React from "react";
import { CSSTransition } from "react-transition-group";
import "./love.scss";

const Love = ({
  show,
  setBackground,
  handleEnter,
  handleExit,
  handleExited,
}) => {
  if (show) {
    setBackground("#782ecc");
  }

  return (
    <CSSTransition
      in={show}
      timeout={5000}
      classNames="love-transition"
      unmountOnExit
      onEntering={handleEnter}
      onExiting={handleExit}
      onExited={handleExited}
    >
      <div className="love-container">
        <div className="message-text-container">
          <h1 className="message-text">
            I love you so <br></br> much Bubba!
          </h1>
          <h1 className="message-text" style={{ fontSize: "2em" }}>
            This has been such an amazing year! I'm so excited for our future
            together!
          </h1>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Love;
