import React from "react";
import "./start.scss";

function Start({ setStart }) {
  return (
    <div className="start-container">
      <button
        className="btn spotify-green-button"
        onClick={() => {
          setStart(true);
        }}
      >
        Start
      </button>{" "}
    </div>
  );
}

export default Start;
